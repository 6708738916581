<template>
  <layout-vertical>

    <router-view />

  </layout-vertical>
</template>

<script>
import BasicLayout from "@/layouts/vertical/BasicLayout";
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    //AppCustomizer,
    BasicLayout,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
